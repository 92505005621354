<template>
  <div class="radio-list">
    <div
      class="radio-item"
      v-for="item in list"
      :key="item.key"
      :class="[selected == item.key ? selectedClass : '']"
      @click="selectRadioItem(item)"
    >
      {{ item.value }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 选中项的key值
    value: {
      type: [String, Number],
    },
    // 单选列表 格式key value
    list: {
      type: Array,
      default: () => [
        {
          key: 1,
          value: "选项1",
        },
        {
          key: 2,
          value: "选项2",
        },
      ],
    },
    // 选中的主题类型 text box
    themeType: {
      type: String,
      default: "text",
    },
  },
  data() {
    return {
      selected: "",
    };
  },
  computed: {
    selectedClass() {
      if (this.themeType == "text") {
        return "selected-text";
      } else if (this.themeType == "box") {
        return "selected-box";
      } else {
        return "selected-text";
      }
    },
  },
  mounted() {
    this.selected = this.value;
  },
  methods: {
    // 选择一个单选框
    selectRadioItem(item) {
      this.selected = item.key;

      this.$emit("input", this.selected);
      this.$emit("change", item);
    },
  },
  watch: {
    value(v, ov) {
      if (v != ov) {
        this.selected = v;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.radio-list {
  display: flex;
  .radio-item {
    padding: 5px 14px;
    margin-left: 10px;
    cursor: pointer;
    &.selected-text {
      color: #3883ff;
    }
    &.selected-box {
      background-color: #3883ff;
      color: #ffffff;
    }
  }
}
</style>