<template>
  <div class="search-view">
    <div class="safety-wraper">
      <div class="search-filter-group">
        <div class="search-top">
          <div class="search-sort">
            <span>资质类型:</span>
            <mutiply-list
              :value="[]"
              :list="[
                { key: 1, value: '一级' },
                { key: 2, value: '二级' },
                { key: 3, value: '三级' },
                { key: 4, value: '双软企业' },
                { key: 5, value: 'ISO-2007' },
                { key: 6, value: '其他' },
              ]"
              theme-type="box"
              show-all
            ></mutiply-list>
          </div>
          <div class="search-input-group">
            <input type="text" />
            <button>搜索</button>
          </div>
        </div>

        <div class="search-filter-content">
          <div class="search-filter-item">
            <span class="filter-label">排序:</span>

            <radio-list
              :value="1"
              :list="[
                { key: 1, value: '默认' },
                { key: 2, value: '时间' },
              ]"
              theme-type="text"
            ></radio-list>
          </div>
        </div>
      </div>

      <div class="search-result-group">
        <div class="search-result-item" v-for="item in list" :key="item.id">
          <div class="search-result-image">
            <img :src="item.poster" alt="" />
          </div>
          <div class="search-result-content">
            <div class="search-result-title">{{ item.name }}</div>
            <div class="search-result-count">投标数量 200</div>
            <div class="search-result-sales">年销售额 140,000.00万</div>
            <div class="search-result-totalprice">
              投标保证金总额 500,000.00万
            </div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          :current-page="page.pageNum"
          background
          layout="prev, pager, next"
          :total="page.total"
          @current-change="searchProject"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import RadioList from "@/components/radio-list.vue";
import MutiplyList from "@/components/mutiply-list.vue";

export default {
  components: {
    RadioList,
    MutiplyList,
  },
  data() {
    return {
      list: [],
      page: {
        pageNum: 1,
        total: 0,
      },
    };
  },
  mounted() {
    this.searchProject();
  },
  methods: {
    searchProject() {
      this.$axios.get("/subject/subjectSeach").then((res) => {
        if (res.state == 200) {
          console.log(res.data.list);
          this.list = res.data.list;
          this.page.pageNum = res.data.pageNum;
          this.page.total = res.data.total;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.safety-wraper {
  width: $safety-width;
  margin: 0 auto;

  .search-filter-group {
    margin-top: 64px;
    border: 1px solid #ededed;
    padding: 0 26px;
    .search-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ededed;
      height: 89px;
      .search-sort {
        display: flex;
        align-items: center;
      }
      .search-input-group {
        input {
          width: 184px;
          height: 34px;
          line-height: 34px;
          background: #ffffff;
          border: 1px solid #999999;
          outline: none;
          font-size: 16px;
        }
        button {
          width: 151px;
          // height: 34px;

          background: #3883ff;
          border: 1px solid #3883ff;
          outline: none;
          margin-left: 8px;

          color: #ffffff;
          line-height: 34px;
          letter-spacing: 1px;
          font-size: 18px;
          &:active {
            background: #296ad6;
          }
        }
      }
    }
    .search-filter-content {
      .search-filter-item {
        display: flex;
        align-items: center;
        margin: 20px 0;
        .filter-label {
        }
        .filter-selector {
        }
      }
      .mutiply-select-row {
        border-top: 1px solid #ededed;
        height: 80px;
      }
    }
  }

  .search-result-group {
    margin-top: 26px;
    display: flex;
    flex-wrap: wrap;
    .search-result-item {
      display: flex;
      width: 586px;

      border: 1px solid #efefef;
      padding: 16px;
      box-sizing: border-box;

      margin-bottom: 27px;
      &:nth-child(odd) {
        margin-right: 27px;
      }
      .search-result-image {
        width: 192px;
        height: 192px;
        background-color: #ddd;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .search-result-content {
        margin-left: 59px;
        line-height: 30px;
        .search-result-title {
          color: #333333;

          font-size: 22px;
          margin-bottom: 53px;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 100%;
            margin-top: 25px;
            width: 29px;
            height: 2px;
            background-color: #3883ff;
          }
        }

        .search-result-count {
          color: #333333;
          font-size: 16px;
        }
        .search-result-sales {
          color: #333333;
          font-size: 16px;
        }
        .search-result-totalprice {
          color: #fa6400;
          font-size: 16px;
        }
      }
    }
  }
  .pagination {
    text-align: right;
    margin-bottom: 114px;
  }
}
</style>