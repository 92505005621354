<template>
  <div class="mutiply-list">
    <!-- <div
      class="mutiply-item"
      :class="[isAll ? selectedClass : '']"
      @click="selectAll"
      v-if="showAll"
    >
      全部
    </div> -->
    <div
      class="mutiply-item"
      v-for="item in list"
      :key="item.key"
      :class="[selected.includes(item.key) ? selectedClass : '']"
      @click="selectMutiplyItem(item)"
    >
      {{ item.value }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 选中项的key值
    value: {
      type: [Array],
    },
    // 单选列表 格式key value
    list: {
      type: Array,
      default: () => [
        {
          key: 1,
          value: "选项1",
        },
        {
          key: 2,
          value: "选项2",
        },
      ],
    },
    // 选中的主题类型 text box
    themeType: {
      type: String,
      default: "text",
    },
    // 是否显示全部
    showAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],

      isAll: false,
    };
  },
  computed: {
    selectedClass() {
      if (this.themeType == "text") {
        return "selected-text";
      } else if (this.themeType == "box") {
        return "selected-box";
      } else {
        return "selected-text";
      }
    },
  },
  mounted() {
    this.selected = this.value;
    if (this.value.length == 0) this.isAll = true;
  },
  methods: {
    // 选择一个单选框
    selectMutiplyItem(item) {
      let idx = this.selected.findIndex((key) => key == item.key);
      if (idx == -1) {
        this.selected.push(item.key);
      } else {
        this.selected.splice(idx, 1);
      }
      if (this.showAll) {
        this.isAll = false;
      }

      this.$emit("input", this.selected, this.isAll);
      this.$emit("change", this.selected);
    },
    // 选中全部时 清空后面的选项列表
    selectAll() {
      this.isAll = true;
      this.selected = [];
    },
  },
  watch: {
    value(v, ov) {
      if (v != ov) {
        this.selected = v;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mutiply-list {
  display: flex;
  .mutiply-item {
    padding: 5px 14px;
    margin-left: 10px;
    cursor: pointer;
    &.selected-text {
      color: #3883ff;
    }
    &.selected-box {
      background-color: #3883ff;
      color: #ffffff;
    }
  }
}
</style>