var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-view"},[_c('div',{staticClass:"safety-wraper"},[_c('div',{staticClass:"search-filter-group"},[_c('div',{staticClass:"search-top"},[_c('div',{staticClass:"search-sort"},[_c('span',[_vm._v("资质类型:")]),_c('mutiply-list',{attrs:{"value":[],"list":[
              { key: 1, value: '一级' },
              { key: 2, value: '二级' },
              { key: 3, value: '三级' },
              { key: 4, value: '双软企业' },
              { key: 5, value: 'ISO-2007' },
              { key: 6, value: '其他' } ],"theme-type":"box","show-all":""}})],1),_vm._m(0)]),_c('div',{staticClass:"search-filter-content"},[_c('div',{staticClass:"search-filter-item"},[_c('span',{staticClass:"filter-label"},[_vm._v("排序:")]),_c('radio-list',{attrs:{"value":1,"list":[
              { key: 1, value: '默认' },
              { key: 2, value: '时间' } ],"theme-type":"text"}})],1)])]),_c('div',{staticClass:"search-result-group"},_vm._l((_vm.list),function(item){return _c('div',{key:item.id,staticClass:"search-result-item"},[_c('div',{staticClass:"search-result-image"},[_c('img',{attrs:{"src":item.poster,"alt":""}})]),_c('div',{staticClass:"search-result-content"},[_c('div',{staticClass:"search-result-title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"search-result-count"},[_vm._v("投标数量 200")]),_c('div',{staticClass:"search-result-sales"},[_vm._v("年销售额 140,000.00万")]),_c('div',{staticClass:"search-result-totalprice"},[_vm._v(" 投标保证金总额 500,000.00万 ")])])])}),0),_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"current-page":_vm.page.pageNum,"background":"","layout":"prev, pager, next","total":_vm.page.total},on:{"current-change":_vm.searchProject}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-input-group"},[_c('input',{attrs:{"type":"text"}}),_c('button',[_vm._v("搜索")])])}]

export { render, staticRenderFns }